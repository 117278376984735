import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import React from 'react'
import { graphql } from 'gatsby'
import Super73Youth from '~/components/Super73Youth'

const Super73YouthPage = ({ data }) => {
  const { page } = data

  if (page) {
    return (
      <Layout showBreadcrumbs={false}>
        <Metadata
          title={page.metaTitle || page.title}
          description={
            page.metaDescription?.metaDescription ||
            `Super73's ${page.title} page default description`
          }
        />
        <Super73Youth page={page} />
      </Layout>
    )
  }
  return <Layout></Layout>
}

export default Super73YouthPage

export const query = graphql`
  query YouthPage($locale: String) {
    page: contentfulPage(slug: { eq: "youth" }, node_locale: { eq: $locale }) {
      title
      metaTitle
      metaDescription {
        metaDescription
      }
      hero {
        ...ContentHeroFragment
      }
      sections {
        ...ContentSectionFragment
      }
    }
  }
`
