/**@jsx jsx */
import { Box, Flex, Heading, jsx } from 'theme-ui'
import React, { Fragment, useEffect, useState } from 'react'
import Modal from '../Generic/Modal'
import YouthHero from './Hero'
import PreOrders from './PreOrders'
import PreOrderColorButtons from './PreOrderColorButtons'
import ContentBlock from './ContentBlock'
import StudioShots from './StudioShots'
import TermsPopup from './Terms/TermsPopup'
import HotspotsSection from './HotspotsSection'
import RichTextRenderer from '../Content/RichText'
import useMatchMedia from '~/hooks/utils/useMatchMedia'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Logo from '~/assets/images/logos/logo-small.svg'
import useDebounce from '~/hooks/utils/use-debounce'

const Super73Youth = ({ page }) => {
  const heroBg = useStaticQuery(graphql`
    query heroBg {
      contentfulAsset(title: { eq: "youth-hero-bg" }) {
        file {
          url
        }
      }
    }
  `)

  const bgUrl = heroBg.contentfulAsset?.file?.url
  const { hero, sections } = page
  const [showVideo, setShowVideo] = useState(false)
  const [showTC, setShowTC] = useState(false)
  const [activeLink, setActiveLink] = useState(null)
  const [customVideo, setCustomVideo] = useState(null)

  const preorders = sections.find(section => section.slug === 'pre-orders')
  const fullWidthSections = sections.filter(
    section =>
      section.media && section.media[0].__typename === 'ContentfulImageSlider'
  )
  const studioShots = sections.find(
    section => section.slug === 'youth-studio-shots'
  )
  const bottomBanner = sections.find(
    section => section.slug === 'bottom-banner'
  )

  const hotspotsSection = sections.find(section => section.slug === 'hot-spots')
  const hotspots = hotspotsSection?.media && hotspotsSection.media[0]

  const disclaimer = sections.find(
    section => section.slug === 'youth-disclaimer'
  )

  const [showFloatingButton, setShowFloatingButton] = useState(false)
  const isMobile = useMatchMedia('(max-width: 1023px)')

  const [scrollAmount, setScrollAmount] = useState(0)
  const setScrollDesktop = () => {
    setScrollAmount(document.querySelector('main').scrollTop)
  }
  const setScrollMobile = () => {
    if (typeof window !== 'undefined') {
      setScrollAmount(window.scrollY)
    }
  }
  useEffect(() => {
    document
      .querySelector('main')
      .addEventListener('scroll', setScrollDesktop, { passive: true })
    document.addEventListener('scroll', setScrollMobile, { passive: true })
    return () => {
      document
        .querySelector('main')
        .removeEventListener('scroll', setScrollDesktop, { passive: true })
      document.removeEventListener('scroll', setScrollMobile, { passive: true })
    }
  }, [])

  const debouncedScroll = useDebounce(scrollAmount, 100)

  useEffect(() => {
    if (typeof debouncedScroll === 'number' && typeof window !== 'undefined') {
      if (debouncedScroll > window.innerHeight) {
        setShowFloatingButton(true)
      } else {
        setShowFloatingButton(false)
      }
    }
  }, [debouncedScroll])

  // Disable scroll when modal active
  useEffect(() => {
    if (showTC) {
      document.querySelector('main').classList.add('no-scroll')
      document.body.classList.add('no-scroll')
      document.documentElement.classList.add('no-scroll')
    } else {
      document.querySelector('main').classList.remove('no-scroll')
      document.body.classList.remove('no-scroll')
      document.documentElement.classList.remove('no-scroll')
    }
  }, [showTC])
  return (
    <Fragment>
      {showTC && (
        <Modal closeModal={() => setShowTC(false)}>
          <TermsPopup close={() => setShowTC(false)} link={activeLink} />
        </Modal>
      )}
      {showVideo && (
        <Modal closeModal={() => setShowVideo(false)}>
          <video
            playsInline
            autoPlay
            controls
            sx={{
              width: ['90vw', '60vw'],
              height: 'auto',
              zIndex: 1,
              animation: 'fadeInUp 0.5s ease'
            }}
          >
            <source
              src={hero.image.file.url}
              type={hero.image.file.contentType}
            />
          </video>
        </Modal>
      )}

      {customVideo && (
        <Modal closeModal={() => setCustomVideo(null)}>
          <video
            playsInline
            autoPlay
            controls
            sx={{
              width: ['90vw', '60vw'],
              height: 'auto',
              zIndex: 1,
              animation: 'fadeInUp 0.5s ease'
            }}
          >
            <source src={customVideo} />
          </video>
        </Modal>
      )}
      {/* Pop up navbar on scroll */}
      <Flex
        sx={{
          position: 'fixed',
          backgroundColor: 'black',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          top: 0,
          left: 0,
          padding: ['10px 8px', null, '10px 20px'],
          gap: '15px',
          zIndex: 10,
          transform: showFloatingButton
            ? 'translate(0, 0)'
            : 'translate(0,-100%)',
          transition: '0.55s ease',
          button: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            lineHeight: 'unset',
            padding: ['1px 15px 0', '1px 36px 0'],
            backgroundColor: 'transparent !important',
            color: 'white !important',
            border: '2px solid white',
            width: '100%',
            maxWidth: '228px',
            height: ['32px', '44px'],
            fontSize: ['1.4rem', '1.5rem']
          }
        }}
      >
        <Link aria-label="Home" to="/" sx={{ display: 'flex' }}>
          <Logo
            sx={{
              width: ['140px', null, '170px'],
              height: 'auto',
              flexShrink: 0
            }}
          />
        </Link>
        {preorders && (
          <PreOrderColorButtons
            section={preorders}
            setShowTC={setShowTC}
            setActiveLink={setActiveLink}
          />
        )}
      </Flex>

      <YouthHero
        hero={hero}
        bgUrl={bgUrl}
        setShowVideo={setShowVideo}
        onClick={() => false}
      />
      {preorders && (
        <PreOrders
          section={preorders}
          setShowTC={setShowTC}
          setActiveLink={setActiveLink}
        />
      )}
      {fullWidthSections[0] && <ContentBlock section={fullWidthSections[0]} />}
      {hotspotsSection && hotspots && (
        <HotspotsSection section={hotspotsSection} hotspots={hotspots} />
      )}
      {fullWidthSections.slice(1).map(section => (
        <ContentBlock section={section} setCustomVideo={setCustomVideo} />
      ))}
      {studioShots && <StudioShots section={studioShots} />}
      {bottomBanner && (
        <button
          onClick={() => {
            if (typeof window !== 'undefined' && window.analytics) {
              window.analytics.track('Youth CTA Clicked', {
                label: 'youth-cta-evergreen-bottom'
              })
            }
            let el = document.getElementById('bikes')
            if (el) {
              el.scrollIntoView({
                behavior: 'smooth',
                block: isMobile ? 'start' : 'center'
              })
            }
          }}
          sx={{
            display: 'block',
            width: '100%',
            background: bgUrl && `url(${bgUrl})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'repeat',
            backgroundColor: 'black',
            color: 'white',
            textAlign: 'center',
            py: '25px',
            px: 0,
            border: 'none',
            cursor: 'pointer'
          }}
        >
          <Heading
            sx={{
              fontSize: ['1.4rem', null, null, '3.4rem'],
              letterSpacing: ['0.05em', '0.1em'],
              lineHeight: '100%'
            }}
          >
            {bottomBanner.title}
          </Heading>
        </button>
      )}
      {disclaimer && (
        <Box
          sx={{
            maxWidth: '1100px',
            m: ['36px auto 71px', null, '71px auto'],
            padding: '0 20px'
          }}
        >
          <RichTextRenderer richText={disclaimer.copy} />
        </Box>
      )}
    </Fragment>
  )
}

export default Super73Youth
