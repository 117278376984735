/**@jsx jsx */
import { Box, jsx } from 'theme-ui'
import { useEffect, useState } from 'react'

const Hotspots = ({
  hotspots,
  setLabel,
  setDetails,
  forwardSx = {},
  color
}) => {
  const { image, hotspots: spots } = hotspots
  const [currentHotspot, setCurrentHotspot] = useState(null)
  const updateSpot = e => {
    if (!e.currentTarget.classList.contains('selected')) {
      document.querySelector('.spot.selected').classList.remove('selected')
      e.currentTarget.classList.add('selected')
    }
    setCurrentHotspot(e.currentTarget.id)
  }
  useEffect(() => {
    const svgSpots = document.querySelectorAll('svg .spot')
    if (spots && spots.length > 0) {
      setCurrentHotspot(spots[0].area)
      const spot = document.querySelector(`.spot#${spots[0].area}`)
      if (spot) {
        spot.classList.add('selected')
        setLabel(spots[0].label)
        setDetails(spots[0].details)
      }
    }

    if (svgSpots) {
      svgSpots.forEach(spot => {
        spot.addEventListener('click', updateSpot)
      })
    }
  }, [])

  useEffect(() => {
    const newSpot = spots.find(spot => spot.area === currentHotspot)
    if (newSpot) {
      setLabel(newSpot.label)
      setDetails(newSpot.details)
    }
  }, [currentHotspot])

  useEffect(() => {
    const currentImage = document.querySelector('use.visible')
    if (currentImage) {
      if (!currentImage.classList.contains(color)) {
        currentImage.classList.remove('visible')
        document.querySelector(`use.${color}`).classList.add('visible')
      }
    }
  }, [color])
  return (
    <Box
      dangerouslySetInnerHTML={{ __html: image.svg.originalContent }}
      sx={{
        width: '100%',
        ...forwardSx,
        svg: {
          width: '100%',
          height: 'auto',
          '.spot': {
            cursor: 'pointer',
            display: ['none', null, 'block'],
            '&.selected': {
              fill: '#de2d61',
              opacity: 1
            }
          },
          '.spot + path': {
            pointerEvents: 'none',
            display: ['none', null, 'block']
          },
          use: {
            transition: '0.3s ease-out',
            '&:not(.visible)': {
              opacity: 0
            }
          }
        }
      }}
    />
  )
}

export default Hotspots
