/**@jsx jsx */
import { Text, Flex, jsx } from 'theme-ui'
const PreOrderColorButtons = ({ section, setShowTC, setActiveLink }) => {
  const { media } = section
  return (
    <Flex
      sx={{ flexDirection: ['column', 'row', 'row', 'row'], align: 'center' }}
    >
      <Text
        sx={{
          fontSize: '1.5rem',
          fontWeight: 'bold',
          fontFamily: 'primary',
          color: 'white',
          display: 'flex',
          justifyContent: ['center', 'start', 'start'],
          alignItems: 'center',
          mb: ['8px', 0, 0]
        }}
      >
        RESERVE NOW
      </Text>
      <Flex sx={{ flexDirection: ['row'], align: 'center' }}>
        {media.map(card => {
          const { title, label, ctaLink, backgroundColor } = card
          return (
            <Flex
              role="button"
              aria-label={`Choose Color Preference - ${title}`}
              title={`Choose Color Preference - ${title}`}
              onClick={() => {
                if (typeof window !== 'undefined') {
                  window.analytics.track('Youth CTA Clicked', {
                    label: label
                  })
                }
                setActiveLink(ctaLink)
                setShowTC(true)
              }}
              sx={{
                backgroundColor: `${backgroundColor} !important`,
                borderRadius: '8px',
                mx: ['10px', '12px', '12px', '12px'],
                border: '1px solid white',
                width: ['30px', null, '40px'],
                height: ['30px', null, '40px'],
                opacity: '0.9',
                ':hover': {
                  opacity: '1',
                  cursor: 'pointer',
                  boxShadow: '0 0 4px white',
                  transition: 'all 0.3s ease-in-out'
                }
              }}
            />
          )
        })}
      </Flex>
    </Flex>
  )
}

export default PreOrderColorButtons
