/**@jsx jsx */
import { GatsbyImage } from 'gatsby-plugin-image'
import { useEffect, useRef, useState } from 'react'
import { Box, Flex, Text, jsx } from 'theme-ui'
import { useOnScreen } from '~/hooks/utils/use-on-screen'
import ImageHeading from '../Generic/ImageHero/ImageHeading'
import CTA from './CTA'
const YouthHero = ({ hero, bgUrl, setShowVideo, onClick }) => {
  const {
    name,
    image,
    poster,
    heading,
    imageHeading,
    subheading,
    buttonText,
    buttonLink,
    buttonBackgroundColor = '#000000',
    buttonTextColor = '#FFFFFF'
  } = hero
  const fullScreen = true

  return (
    <Flex
      sx={{
        width: '100%',
        minHeight: '660px',
        position: 'relative',
        flexDirection: 'column'
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100vh',
          position: 'relative'
        }}
      >
        {image.file.contentType.split('/')[0] === 'video' && image.description && (
          <Text
            sx={{
              position: 'absolute',
              color: 'white',
              bottom: '20px',
              right: '20px',
              fontSize: '12px',
              textTransform: 'uppercase'
            }}
          >
            {image.description}
          </Text>
        )}

        {image.file.contentType.split('/')[0] !== 'video' ? (
          <GatsbyImage
            image={image.gatsbyImageData}
            alt={image?.description ?? image?.title}
            sx={{ height: '100%', minHeight: '300px' }}
          />
        ) : fullScreen ? (
          <video
            poster={poster?.gatsbyImageData?.images?.fallback?.src}
            muted
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
              // cursor: 'pointer'
            }}
            loop
            playsInline
            autoPlay
          >
            <source src={image.file.url} type={image.file.contentType} />
          </video>
        ) : (
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              pb: ['75.85%', null, '50%']
            }}
          >
            <video
              poster={poster?.gatsbyImageData?.images?.fallback?.src}
              muted
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
              loop
              playsInline
              autoPlay
            >
              <source src={image.file.url} type={image.file.contentType} />
            </video>
          </Box>
        )}
      </Box>
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          width: '100%',
          background: bgUrl && `url(${bgUrl})`,
          backgroundSize: ['cover', 'contain'],
          backgroundRepeat: 'repeat',
          p: ['4.5rem 2rem', '6.5rem 3rem'],

          color: 'white',
          svg: {
            maxWidth: '100%',
            height: 'auto'
          },
          '.imageHeading': {
            mb: '5rem'
          }
        }}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'inline-block',
            '.imageHeading': {
              mb: ['10px !important', null, null, '0 !important']
            }
          }}
        >
          {name && !imageHeading && (
            <h1
              sx={{
                color: 'white',
                fontSize: ['2.8rem', '4rem', null, '5rem'],
                letterSpacing: '0.4em !important',
                fontWeight: '900',
                fontFamily: 'primary',
                m: ['0 0 2rem', null, '0 0 1rem']
              }}
            >
              {name}
            </h1>
          )}
          {imageHeading && (
            <ImageHeading
              svg={imageHeading.svg}
              fluid={imageHeading.fluid}
              file={imageHeading.file}
              alt={imageHeading.file.filename}
            />
          )}
        </Box>
        {heading && heading.heading && (
          <h1
            sx={{
              mt: ['15px', '21px'],
              mb: ['10px', '5px'],
              fontWeight: '500',
              fontSize: ['2.2rem', '3.4rem'],
              lineHeight: 1.2,
              letterSpacing: '0.1em'
            }}
          >
            {heading.heading}
          </h1>
        )}

        {subheading && subheading.subheading && (
          <h2
            sx={{
              fontWeight: '500',
              color: 'white',
              fontSize: ['1.8rem', '2.8rem'],
              letterSpacing: '0.1em',
              margin: '0'
            }}
          >
            {subheading.subheading}
          </h2>
        )}
      </Flex>
    </Flex>
  )
}

export default YouthHero
