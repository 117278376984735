/**@jsx jsx */
import { Flex, Heading, Box, Text, jsx } from 'theme-ui'
import React, { useState } from 'react'
import Hotspots from '../Generic/Hotspots'
import ColorSwatch from '~/assets/images/icons/color_swatch.svg'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const HotspotsSection = ({ section, hotspots }) => {
  const colors = {
    yellow: '#FFE701',
    pink: '#de2d61',
    black: '#231F20',
    blue: '#007eae'
  }
  const [label, setLabel] = useState('')
  const [details, setDetails] = useState(null)
  const [color, setColor] = useState('yellow')
  const bgImage = section.images && section.images[0]

  return (
    <Flex
      sx={{
        position: 'relative',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        pt: '62px',
        pb: ['62px', null, '42px'],
        background: `url(${bgImage.gatsbyImageData?.images?.fallback?.src})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      }}
    >
      <Heading
        as="h1"
        sx={{
          letterSpacing: '0.1em',
          fontSize: ['2.8rem', null, null, '3.6rem'],
          mb: ['20px', null, '65px']
        }}
      >
        {section.title}
      </Heading>
      <Flex
        sx={{
          position: ['static', null, 'absolute'],
          top: 0,
          left: 0,
          flexDirection: 'column',
          maxWidth: '320px',
          lineHeight: '135%',
          letterSpacing: '0.02em',
          top: '17%',
          left: '7%',
          pl: '31px',
          mb: ['30px', null, '0'],
          borderLeft: '5px solid black',
          display: ['none', null, 'flex']
        }}
      >
        <Heading as="h2" sx={{ fontSize: '2.4rem', mb: 1 }}>
          {label}
        </Heading>
        <Text
          sx={{ p: { fontSize: '1.8rem', fontWeight: 'medium', m: 0 } }}
          dangerouslySetInnerHTML={{
            __html: details?.childMarkdownRemark?.html
          }}
        />
      </Flex>
      <Hotspots
        forwardSx={{
          width: ['100%', null, '71%'],
          mx: 'auto',
          maxWidth: '1360px'
        }}
        hotspots={hotspots}
        setLabel={setLabel}
        setDetails={setDetails}
        color={color}
      />
      <Box
        sx={{
          width: '100%',
          position: 'relative',
          display: ['block', null, 'none'],
          '.slick-dots': {
            bottom: '-12px',
            'button::before': { fontSize: '10px' }
          }
        }}
      >
        <Slider dots arrows={false} infinite autoplay autoplaySpeed={7000}>
          {hotspots?.hotspots?.map(({ label, details }, i) => {
            return (
              <Flex
                key={label + i}
                sx={{
                  flexDirection: 'column',
                  padding: '20px 25px',
                  lineHeight: '135%',
                  letterSpacing: '0.02em'
                }}
              >
                <Heading as="h2" sx={{ fontSize: '2.4rem', mb: 3 }}>
                  {label}
                </Heading>
                <Text
                  sx={{ p: { fontSize: '1.8rem', fontWeight: 'medium', m: 0 } }}
                  dangerouslySetInnerHTML={{
                    __html: details?.childMarkdownRemark?.html
                  }}
                />
              </Flex>
            )
          })}
        </Slider>
      </Box>
      <Flex
        sx={{
          position: ['static', null, 'absolute'],
          flexDirection: 'column',
          top: '17%',
          right: '6%',
          width: ['100%', null, '220px'],
          justifyContent: 'center',
          alignItems: 'center',
          gap: '20px',
          height: ['auto', null, '230px'],
          mt: ['30px', null, 0],
          small: {
            position: ['static', null, 'absolute'],
            bottom: '-14px',
            right: '-6px',
            transform: [null, null, 'translate(0, 100%)'],
            fontSize: '10px',
            fontWeight: '900',
            letterSpacing: '0.08em'
          }
        }}
      >
        <Flex
          sx={{
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            gap: '10px',
            button: {
              cursor: 'pointer',
              padding: 0,
              margin: 0,
              border: 'none',
              background: 'none',
              position: ['static', null, 'absolute'],
              width: ['50px', null, 'auto'],
              height: ['50px', null, 'auto'],
              svg: { width: '100%', height: 'auto' }
            }
          }}
        >
          <button
            onClick={() => setColor('pink')}
            aria-label="Change to color pink"
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              path: { fill: colors.pink }
            }}
          >
            <ColorSwatch />
          </button>
          <button
            onClick={() => setColor('black')}
            aria-label="Change to color black"
            sx={{
              position: 'absolute',
              top: 0,
              left: '50%',
              transform: [null, null, 'translate(-50%, 53%)'],
              path: { fill: colors.black }
            }}
          >
            <ColorSwatch />
          </button>
          <button
            onClick={() => setColor('yellow')}
            aria-label="Change to color yellow"
            sx={{
              position: 'absolute',
              bottom: 0,
              left: '50%',
              transform: [null, null, 'translate(-50%, -53%)'],
              path: { fill: colors.yellow }
            }}
          >
            <ColorSwatch />
          </button>
          <button
            onClick={() => setColor('blue')}
            aria-label="Change to color blue"
            sx={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              path: { fill: colors.blue }
            }}
          >
            <ColorSwatch />
          </button>
        </Flex>
        <small>CHANGE COLOR</small>
      </Flex>
    </Flex>
  )
}

export default HotspotsSection
