/**@jsx jsx */
import { GatsbyImage } from 'gatsby-plugin-image'
import { Flex, IconButton, Box, Heading, Text, jsx } from 'theme-ui'
import vhs from './images/vhs.png'
const ContentBlock = ({ section, setCustomVideo }) => {
  const slider = section.media[0]
  const slide = slider.slides[0]
  const video = slide?.cta
  const { title, label, description, background, layoutAlignment, textColor } =
    slide
  const showColorBlocks = label === 'color-blocks'
  const colors = ['#de2d61', '#231F20', '#FFE701', '#007eae']
  let alignment = ['flex-end', 'flex-end']
  switch (layoutAlignment) {
    case 'left':
      alignment = ['flex-start', 'flex-end']
      break
    case 'center':
      alignment = ['center', 'center']
      break
    case 'top':
      alignment = ['center', 'flex-start']
      break
    case 'bottom':
      alignment = ['center', 'flex-end']
      break
    default:
      break
  }
  return (
    <Flex
      sx={{
        width: '100%',
        minHeight: '600px',
        position: 'relative',
        '.gatsby-image-wrapper': {
          width: '100%'
        }
      }}
    >
      {video && (
        <IconButton
          onClick={() => {
            if (typeof window !== 'undefined') {
              window.analytics.track('Youth CTA Clicked', {
                label: 'vhs'
              })
            }
            setCustomVideo(video)
          }}
          sx={{
            position: 'absolute',
            bottom: [null, null, null, '70px'],
            right: ['20px', null, null, '70px'],
            top: ['20px', null, null, 'auto'],
            zIndex: 2,
            padding: 0,
            width: 'auto',
            height: 'auto',
            cursor: 'pointer'
          }}
        >
          <img
            src={vhs}
            alt="Vhs play icon"
            sx={{ width: ['70px', null, '170px'], height: 'auto' }}
          />
        </IconButton>
      )}
      {background.file.contentType.includes('video') ? (
        <video
          muted
          sx={{
            width: '100%',
            height: 'auto',
            objectFit: 'cover',
            zIndex: 1,
            position: 'relative'
          }}
          loop
          playsInline
          autoPlay
        >
          <source
            src={background.file.url}
            type={background.file.contentType}
          />
        </video>
      ) : (
        <GatsbyImage
          sx={{ zIndex: 1 }}
          image={background.gatsbyImageData}
          alt={background?.description ?? background?.title}
        />
      )}
      <Flex
        sx={{
          position: 'absolute',
          padding: ['20px', null, null, '70px'],
          width: '100%',
          height: '100%',
          left: 0,
          top: 0,
          justifyContent: ['center', null, null, alignment[0]],
          alignItems: ['flex-end', null, null, alignment[1]]
        }}
      >
        <Flex
          sx={{
            padding: ['30px 30px 30px 40px', null, null, '36px 41px 43px 61px'],
            background: 'rgba(0,0,0,0.7)',
            borderRadius: '10px',
            flexDirection: 'column',
            maxWidth: '620px',
            zIndex: 1
          }}
        >
          <Heading
            sx={{
              color: '#FFF862',
              maxWidth: '450px',
              lineHeight: '116%',
              fontSize: ['2.8rem', null, null, '3.6rem']
            }}
          >
            {title}
          </Heading>
          <Text
            sx={{
              color: textColor,
              lineHeight: '110%',
              h2: { fontSize: ['1.8rem', null, null, '2rem'], m: 0 },
              'p, h3': {
                m: 0,

                fontSize: ['1.5rem', null, null, '1.8rem']
              },
              h3: { fontWeight: 'medium' },
              p: { my: 2, fontWeight: '400' },
              borderLeft: '5px solid #FFF862',
              pl: '30px',
              mt: '17px'
            }}
            dangerouslySetInnerHTML={{
              __html: description.childMarkdownRemark.html
            }}
          />
        </Flex>
      </Flex>
      {showColorBlocks && (
        <Flex
          sx={{
            zIndex: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
          }}
        >
          {colors.map(color => (
            <Box
              sx={{ width: '25%', backgroundColor: color, height: '100%' }}
            />
          ))}
        </Flex>
      )}
    </Flex>
  )
}

export default ContentBlock
