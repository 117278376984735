/**@jsx jsx */
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box, Button, Link, Flex, jsx } from 'theme-ui'
const PreOrders = ({ section, setShowTC, setActiveLink }) => {
  const { media } = section
  return (
    <Flex id="bikes" sx={{ flexDirection: ['column', null, null, 'row'] }}>
      {media.map(card => {
        const {
          title,
          slug,
          label,
          media: image,
          callToAction,
          ctaLink,
          backgroundColor,
          textColor = '#FFFFFF'
        } = card
        return (
          <Flex
            sx={{
              backgroundColor,
              flex: 1,
              flexDirection: 'column',
              alignItems: 'center',
              position: 'relative',
              pb: '78px',
              gap: '10px'
            }}
          >
            <GatsbyImage
              alt={image?.description ?? image?.title}
              sx={{ mt: '-65px' }}
              image={image.gatsbyImageData}
            />
            <Button
              aria-label={`Choose Color Preference - ${title}`}
              title={`Choose Color Preference - ${title}`}
              onClick={() => {
                if (typeof window !== 'undefined') {
                  window.analytics.track('Youth CTA Clicked', {
                    label: label
                  })
                }
                setActiveLink(ctaLink)
                setShowTC(true)
              }}
              sx={{
                padding: '9px 40px !important',
                fontSize: '1.8rem',
                color: 'white !important',
                backgroundColor: 'black !important',
                borderRadius: 0,
                letterSpacing: '0.1em',
                lineHeight: '127% !important',
                width: 'auto !important',
                height: 'auto !important',
                fontWeight: '900 !important',
                fontFamily: 'Neue Haas GDP !important',
                ':hover': {
                  color:
                    slug === 'obsidian'
                      ? '#9F9F9F !important'
                      : `${backgroundColor} !important`
                }
              }}
            >
              {callToAction}
            </Button>
            <h1
              sx={{
                color: textColor,
                fontSize: '1.6rem',
                fontWeight: '900',
                width: '100%',
                textAlign: 'center',
                m: 0,
                position: 'absolute',
                bottom: '40px',
                left: '50%',
                transform: 'translateX(-50%)'
              }}
            >
              {title}
            </h1>
          </Flex>
        )
      })}
    </Flex>
  )
}

export default PreOrders
